// === 订单管理 ===

const RouterOrder = [
  // 订单
  {
    path: "/transaction",
    component: () => import("@/views/order/TransactionIndex"),
    meta: {
      requireLogin: true,
      title: "交易记录",
    },
  },
  {
    path: "/order",
    component: () => import("@/views/order/OrderIndex"),
    meta: {
      requireLogin: true,
      title: "订单管理",
    },
  },
  {
    path: "/order/detail/:id",
    props: true,
    component: () => import("@/views/order/OrderDetail.vue"),
    meta: {
      requireLogin: true,
      title: "订单详情信息",
    },
  },
];

export default RouterOrder;
