import axios from "axios";
import router from "@/router";
import store from "@/store";

import { Message } from "element-ui";

axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL ? process.env.VUE_APP_API_BASEURL : '/api'

//请求拦截：
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json"
    };
    //若用户曾经执行过登录
    if (store.state.user.isLogin) {
      const { token } = store.state.user;
      config.headers["x-token"] = token || "No Token";
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);


//响应拦截：
axios.interceptors.response.use(
  (response) => {
    if (!response.data.code) {
      return response;
    }

    if (response.data.code != 200) {
      switch (response.data.code) {
        case 9999:
          Message({
            type: 'info',
            message: '已登出',
            duration: 1500
          });
          store.dispatch('LogoutHandle');
          router.replace('/login');
          return Promise.reject(response);
          break;
        case 401:
          Message({
            type: "error",
            message: response.data.msg,
            duration: 1500,
          });
          localStorage.removeItem('baseInfo')
          store.dispatch("LogoutHandle");
          router.push("/login");
          return Promise.reject(response);
        case 404:
          router.push("/404");
          return Promise.reject(response);
        case 405:
          router.push("/welcome");
          return Promise.reject(response);
        default:
          Message({
            type: "error",
            message: response.data.msg,
            duration: 1500,
          });
          return Promise.reject(response);
      }
    }

    return response;
  },

  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          Message({
            type: 'info',
            message: '已登出',
            duration: 1500
          });
          store.dispatch('LogoutHandle');
          router.replace('/login');
          return Promise.reject(error);
          break;
        case 404:
          router.push("/404");
          break;
        case 502:
          Message({
            type: "error",
            message: "请求出错，请稍后再试",
            duration: 1500,
            onClose: () => {
              router.push("/500");
            },
          });
          break;
        default:
          Message({
            type: "error",
            message:
              error.response.data.error.message || "请求失败，请稍后再试",
            duration: 1500,
          });
          break;
      }
    }
    return Promise.reject(error);
  }
);

export function get(url, params, options) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        ...options,
      })
      .then(function (response) {
        resolve(response.data.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function remove(url, params, options) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        params: params,
        ...options,
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function Delete(url, params, options) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      data: params,
      ...options
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  })
}


export function postExport(url, data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post", url,
      data,
      responseType: "blob",
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


