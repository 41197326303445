// === 臧品管理 ===

const RouterNft = [
  {
    path: "/nft",
    component: () => import("@/views/nft/NftIndex"),
    meta: {
      requireLogin: true,
      title: "藏品发行",
    },
  },
  {
    path: "/nftpass",
    component: () => import("@/views/nft/NftPass"),
    meta: {
      requireLogin: true,
      title: "藏品列表",
    },
  },
  {
    path: "/nft/detail/:id",
    props: true,
    component: () => import("@/views/nft/NftDetail"),
    meta: {
      requireLogin: true,
      title: "藏品详细信息",
    },
  },
  {
    path: "/nft/create",
    props: true,
    component: () => import("@/views/nft/NftCreate"),
    meta: {
      requireLogin: true,
      title: "藏品发行",
    },
  },
  {
    path: "/nft/update/:id",
    props: true,
    component: () => import("@/views/nft/NftUpdate"),
    meta: {
      requireLogin: true,
      title: "藏品信息编辑",
    },
  },
];

export default RouterNft;
