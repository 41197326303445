// === 用户管理 ===

const RouterUser = [
  // 订单
  {
    path: "/user",
    component: () => import("@/views/user/UserIndex"),
    meta: {
      requireLogin: true,
      title: "用户管理",
    },
  },
];

export default RouterUser;
