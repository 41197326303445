// === 市场管理 ===

const RouterCompose = [
    // 品牌
    {
        path: "/compose",
        component: () => import("@/views/compose/ComposeIndex"),
        meta: {
            requireLogin: true,
            title: "合成管理",
        },
    },
    {
        path: "/compose/create",
        component: () => import("@/views/compose/ComposeCreate"),
        meta: {
            requireLogin: true,
            title: "新增合成",
        },
    },
    {
        path: "/compose/update/:id",
        props: true,
        component: () => import("@/views/compose/ComposeUpdate"),
        meta: {
            requireLogin: true,
            title: "编辑合成",
        },
    },

];

export default RouterCompose;
