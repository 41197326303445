import { get, post } from "./config";

// 登录验证码
export const CaptchaQuery = () => post("/v1/base/captcha");

// 登录
export const PasswordLogin = (params) => post("v1/base/login", params);

// 登出
export const Logout = () => get("v1/base/logout");

// === 选项列表接口 ===

// 文件上传
// // 文件上传
export const FileUpload = (params) =>
  post("v1/fileUploadAndDownload/upload", params);
// export const FilesUpload = params => post('v1/file/upload', params);
