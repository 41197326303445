// === 品牌管理 ===

const RouterAudit = [
  // 品牌
  {
    path: "/audit/market",
    component: () => import("@/views/audit/AuditMarket"),
    meta: {
      requireLogin: true,
      title: "上架信息审核",
    },
  },
  {
    path: "/audit/nft",
    props: true,
    component: () => import("@/views/audit/AuditNft"),
    meta: {
      requireLogin: true,
      title: "藏品信息审核",
    },
  },
  {
    path: "/audit/nftdetail/:id/:nftid",
    props: true,
    component: () => import("@/views/audit/AuditNftDetail"),
    meta: {
      requireLogin: true,
      title: "藏品信息审核",
    },
  },
  {
    path: "/audit/marketdetail/:id/:marketId",
    props: true,
    component: () => import("@/views/audit/AuditMarketDetail"),
    meta: {
      requireLogin: true,
      title: "藏品信息审核",
    },
  },
  {
    path: "/audit/collection",
    props: true,
    component: () => import("@/views/audit/AuditCollection"),
    meta: {
      requireLogin: true,
      title: "藏品集信息审核",
    },
  },
];

export default RouterAudit;
