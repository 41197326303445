// === app管理 ===

const RouterApp = [
    // app
    {
        path: "/app",
        component: () => import("@/views/app/AppIndex"),
        meta: {
            requireLogin: true,
            title: "APP版本管理",
        },
    },

];

export default RouterApp;
