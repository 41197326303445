// === 品牌管理 ===

const RouterAirDrop = [
    // 品牌
    {
        path: "/airdrop",
        component: () => import("@/views/airdrop/AirdropIndex"),
        meta: {
            requireLogin: true,
            title: "空投管理",
        },
    },
    {
        path: "/airdrop/create",
        props: true,
        component: () => import("@/views/airdrop/AirdropCreate"),
        meta: {
            requireLogin: true,
            title: "发放空投",
        },
    },

];

export default RouterAirDrop;
