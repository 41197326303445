// === 新闻/动态管理 ===

const RouterNotice = [{
    path: '/notice',
    component: () => import('@/views/notice/NoticeIndex'),
    meta: {
        requireLogin: true,
        title: "动态管理"
    }
},
{
    path: '/notice/create',
    component: () => import('@/views/notice/NoticeCreate'),
    meta: {
        requireLogin: true,
        title: "新增公告"
    }
},
{
    path: '/notice/detail/:id',
    component: () => import('@/views/notice/NoticeDetail'),
    props: true,
    meta: {
        requireLogin: true,
        title: "公告详情"
    }
},
{
    path: '/notice/update',
    name: "NoticeUpdate",
    component: () => import('@/views/notice/NoticeUpdate'),
    props: true,
    meta: {
        requireLogin: true,
        title: "编辑公告"
    }
},
{
    path: '/banner/create',
    component: () => import('@/views/notice/banner/BannerCreate'),
    props: true,
    meta: {
        requireLogin: true,
        title: "新增轮播图"
    }
},
{
    path: '/banner/update',
    name: "BannerUpdate",
    component: () => import('@/views/notice/banner/BannerUpdate'),
    props: true,
    meta: {
        requireLogin: true,
        title: "编辑轮播图"
    }
},
{
    path: '/poster/create',
    component: () => import('@/views/notice/poster/PosterCreate'),
    props: true,
    meta: {
        requireLogin: true,
        title: "新增邀请海报"
    }
},
{
    path: '/poster/update',
    name: "BannerUpdate",
    component: () => import('@/views/notice/poster/PosterUpdate'),
    props: true,
    meta: {
        requireLogin: true,
        title: "编辑邀请海报"
    }
},


]

export default RouterNotice;
