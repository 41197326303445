import { PasswordLogin, Logout } from '@/api/common'

const user = {
	//用户的基本信息
	state: {
		isLogin: false,
		token: '',
		expire: '',
	},

	mutations: {
		SET_BASEINFO: (state, baseInfo) => {
			state.isLogin = baseInfo.isLogin;
			state.token = baseInfo.token;
			state.expire = baseInfo.expire;
		},

		SET_EXIT: (state) => {
			for (let i in state) {
				state[i] = '';
			}
			state.isLogin = false;
		}
	},

	actions: {

		//登录用户信息处理
		LoginHandler({
			commit
		}, oData) {
			return new Promise((resolve, reject) => {
				PasswordLogin(oData).then(response => {
					const baseInfo = {
						isLogin: true,
						token: response.data.token,
						expire: response.data.expiresAt
					}
					commit('SET_BASEINFO', baseInfo);
					window.localStorage.setItem('baseInfo', JSON.stringify(baseInfo));
					resolve(response);
				}).catch(error => {
					reject(error);
				});
			})
		},

		LogoutHandle({
			commit
		}) {
			return new Promise((resolve, reject) => {
				Logout().then(response => {
					commit('SET_EXIT');
					window.localStorage.removeItem('baseInfo');
					resolve(true);
				}).catch(error => {
					reject(false);
				});
			})
		},

		// 更新登录信息 - 核心是更新token
		UpdateBaseInfo(context, baseInfo) {
			context.commit('SET_BASEINFO', baseInfo);
			window.localStorage.setItem('baseInfo', JSON.stringify(baseInfo));
		},

		// 设置登录信息
		SetLogin(context, baseInfo) {
			context.commit('SET_BASEINFO', baseInfo);
			window.localStorage.setItem('baseInfo', JSON.stringify(baseInfo));
		},
	} //actions-enda
}

export default user