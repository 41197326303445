import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentMenu: '',//系统当前菜单
  },
  mutations: {
    SET_MENU: (state, path) => {
      state.currentMenu = path;
    },
    CLEAR_EXIT: (state) => {
      state.currentMenu = '';
    }
  },
  actions: {
    setMenu(context, path) {
      context.commit('SET_MENU', path);
      window.localStorage.setItem('currentMenu', path);
    },
    clearMenu(context, path) {
      context.commit('CLEAR_EXIT');
    }
  },
  modules: {
    user
  }
})
