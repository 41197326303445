// === 市场管理 ===

const RouterMarket = [
  // 品牌
  {
    path: "/market",
    component: () => import("@/views/market/MarketIndex"),
    meta: {
      requireLogin: true,
      title: "上架管理",
    },
  },
  {
    path: "/nftmarket",
    component: () => import("@/views/market/NftMarket"),
    meta: {
      requireLogin: true,
      title: "藏品市场",
    },
  },
  {
    path: "/market/create",
    props: true,
    component: () => import("@/views/market/MarketCreate.vue"),
    meta: {
      requireLogin: true,
      title: "藏品上架",
    },
  },
  {
    path: "/market/detail/:id",
    props: true,
    component: () => import("@/views/market/MarketDetail.vue"),
    meta: {
      requireLogin: true,
      title: "上架详情信息",
    },
  },
  {
    path: "/market/update/:id",
    props: true,
    component: () => import("@/views/market/MarketUpdate.vue"),
    meta: {
      requireLogin: true,
      title: "藏品上架编辑",
    },
  },

];

export default RouterMarket;
