// === 钱包管理 ===

const RouterBalance = [
    // 钱包
    {
        path: "/cashout",
        component: () => import("@/views/balance/CashoutIndex"),
        meta: {
            requireLogin: true,
            title: "提现审核",
        },
    },
    {
        path: "/paylog",
        component: () => import("@/views/balance/PaylogIndex"),
        meta: {
            requireLogin: true,
            title: "支付记录",
        },
    },
    {
        path: "/account",
        component: () => import("@/views/balance/AccountIndex"),
        meta: {
            requireLogin: true,
            title: "用户账户",
        },
    },

];

export default RouterBalance;
