import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/icons/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/base.css';
Vue.use(ElementUI);

import VueAreaLinkage from 'vue-area-linkage';
import 'vue-area-linkage/dist/index.css'; // v2 or higher
Vue.use(VueAreaLinkage);


import '@/assets/global.less';

Vue.config.productionTip = false

Vue.prototype.$IMGPREFIX = "http://192.168.212.194:8888/"
Vue.prototype.$UPLOADURL = 'http://192.168.212.194:8888/fileUploadAndDownload/upload';

Vue.prototype.$IMGPREFIX = "https://market.leaderchain.cn/"
Vue.prototype.$UPLOADURL = 'https://market.leaderchain.cn/fileUploadAndDownload/upload';



Vue.filter('RFCTimeToNormal', function (RFC3339DateTime) {
  if (!RFC3339DateTime) return '-';
  const date = new Date(RFC3339DateTime).toJSON();
  const datetime = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
  return datetime;
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
