// === 臧品集管理 ===

const RouterCollection = [
    // 品牌
    {
        path: '/collection',
        component: () => import('@/views/collection/CollectionIndex'),
        meta: {
            requireLogin: true,
            title: "臧品集管理"
        }
    },

]

export default RouterCollection;