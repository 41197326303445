// === 品牌管理 ===

const RouterBrand = [
  // 品牌
  {
    path: "/brand",
    component: () => import("@/views/brand/BrandIndex"),
    meta: {
      requireLogin: true,
      title: "品牌管理",
    },
  },
  {
    path: "/brand/asset/:id/:companyName/:created_at/:brandBlockchain",
    props: true,
    component: () => import("@/views/brand/BrandAssetIndex"),
    meta: {
      requireLogin: true,
      title: "品牌签约信息查看",
    },
  },
];

export default RouterBrand;
